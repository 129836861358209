import React, { useContext, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { AddressInterface } from "./index";
import Field from "../../Forms/Field";
import { LocalizedContext } from "../../../services/LocalizedContextService";

import { getAuthUser } from "../../../services/salesforce/Auth";
import { createUserAddress, updateUserAddress } from "../../../services/salesforce/Account/user";
import Button from "../../Button";
import { sendErrorEvents } from "../../../utils/sendErrorEvents";

const AddressForm = ({
  data,
  formState,
  handleCancel
}: {
  data: AddressInterface;
  formState: string;
  handleCancel: any;
}) => {
  const formRef = useRef();
  const { addressId, firstName, lastName, address1, address2, countryCode, phone, city, stateCode, postalCode } = data;
  const formPlaceholders = useContext(LocalizedContext).sanityLabels?.formPlaceholders;
  const { ctaLabels: labels, formsLabels } = useContext(LocalizedContext).sanityLabels || {};
  const errorMessages = useContext(LocalizedContext).sanityLabels?.errorMessages;
  const queryClient = useQueryClient();
  const user = getAuthUser();

  const PHONE_REGEX = new RegExp(/^\+?\d{0,1}\s?-?\(?(\d{3})\)?\s?-?(\d{3})\s?-?(\d{4})$/);
  const handlePhoneNumberValidate = (phoneNumber: any) => {
    return PHONE_REGEX.test(phoneNumber);
  };

  useEffect(() => {
    typeof window !== "undefined" && window.pca && window.pca.load();
  }, []);

  const formFields: any = [
    {
      type: "text",
      name: "addressId",
      label: formsLabels?.addressName,
      defaultValue: addressId,
      rules: {
        required: true
      },
      message: errorMessages?.validAddressName || "Please, enter address name"
    },
    [
      {
        type: "text",
        name: "firstName",
        label: formsLabels?.firstName,
        defaultValue: firstName,
        rules: {
          required: true,
          pattern: {
            value: /^[A-Za-z]+$/i,
            message: "First name should not contain any numbers, special symbols"
          },
          maxLength: {
            value: 50,
            message: "First name should not exceed more than 50 characters"
          }
        },
        message: errorMessages?.validFirstName
      },
      {
        type: "text",
        name: "lastName",
        label: formsLabels?.lastName,
        defaultValue: lastName,
        rules: {
          required: true,
          pattern: {
            value: /^[A-Za-z]+$/i,
            message: "Last name should not contain any numbers, special symbols"
          },
          maxLength: {
            value: 50,
            message: "Last name should not exceed more than 50 characters"
          }
        },
        message: errorMessages?.validLastName
      }
    ],
    {
      type: "text",
      name: "address1",
      label: formsLabels?.address1,
      defaultValue: address1,
      rules: {
        required: true
      },
      message: errorMessages?.validAddress1 || "Please, enter an address",
      autocomplete: "pca-override"
    },
    {
      type: "text",
      name: "address2",
      label: formsLabels?.address2,
      defaultValue: address2,
      rules: {
        required: false
      },
      message: ""
    },
    [
      {
        type: "select",
        name: "country",
        options: [
          {
            value: "US",
            label: "United States"
          }
        ],
        label: formsLabels?.country,
        placeholder: formPlaceholders?.country,
        defaultValue: countryCode,
        rules: {
          required: true,
          minLength: 2,
          maxLength: 3
        },
        message: errorMessages?.validCountry || "Please, enter a valid country code (2 letters)",
        autocomplete: "shipping country"
      },
      {
        type: "text",
        name: "state",
        label: formsLabels?.state,
        placeholder: formPlaceholders?.state,
        defaultValue: stateCode,
        rules: {
          required: true,
          minLength: 2,
          maxLength: 3
        },
        message: errorMessages?.validState || "Please, enter a valid state code (2 letters)"
      }
    ],
    [
      {
        type: "text",
        name: "city",
        label: formsLabels?.city,
        defaultValue: city,
        rules: {
          required: true
        },
        message: errorMessages?.validCity || "Please, enter a city"
      },
      {
        type: "text",
        name: "zipCode",
        label: formsLabels?.zipCode,
        placeholder: formPlaceholders?.zipCode,
        defaultValue: postalCode,
        rules: {
          required: true
        },
        message: errorMessages?.validZipCode || "Please, enter a postal code"
      }
    ],
    {
      type: "number",
      name: "phone",
      label: formsLabels?.phone,
      placeholder: formPlaceholders?.phone,
      defaultValue: phone,
      rules: {
        required: true,
        validate: (value: any) => {
          if (!handlePhoneNumberValidate(value)) {
            return "Invalid phone number. Please try again.";
          }
        },
        minLength: {
          value: 10,
          message: "Phone number should be at least 10 digits"
        }
      },
      message: errorMessages?.validPhone || "Please enter your phone number!"
    }
  ];

  const { mutate: createNewAddress, isLoading: isLoadingNewAddress } = useMutation({
    mutationFn: createUserAddress,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile"] });
      handleCancel();
    },
    onSettled: () => {
      handleCancel();
    }
  });

  const { mutate: updateAddress, isLoading: isLoadingEditAddress } = useMutation({
    mutationFn: updateUserAddress,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile"] });
      handleCancel();
    },
    onSettled: () => {
      handleCancel();
    }
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();

  const handleSubmitClick = () => {
    sendErrorEvents(errors, formFields);
  };
  const onSubmit = (data: any) => {
    const convertedData = {
      ...data,
      postalCode: data.zipCode,
      stateCode: data.state,
      countryCode: data.country
    };

    delete convertedData.zipCode;
    delete convertedData.state;
    delete convertedData.country;

    if (formState === "EDIT") {
      updateAddress({ ...convertedData, customerId: user.rcid });
    } else {
      createNewAddress({ ...convertedData, customerId: user.rcid });
    }
  };

  const handleChange = () => {
    // @ts-ignore
    const formElements = formRef.current.elements;
    setValue("address1", formElements.address1.value);
    setValue("state", formElements.state.value);
    setValue("city", formElements.city.value);
    setValue("zipCode", formElements.zipCode.value);
  };

  return (
    <form
      ref={formRef}
      onChange={handleChange}
      onSubmit={handleSubmit(onSubmit)}
      className={`address-book-card__wrapper is-${formState}`}
    >
      {formState === "EDIT" && <div className="address-book-card__title text__size-h4">{addressId}</div>}
      {formFields.map((field: any, index: number) =>
        Array.isArray(field) ? (
          <div className="row" key={index}>
            {field.map((field: any, index: number) => (
              <div className={`form-group ${field.name}`} key={`key-${index}`}>
                <Field
                  id={field.name}
                  key={field.name}
                  {...field}
                  registerInput={register}
                  errors={errors}
                  message={field.message}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="row" key={index}>
            <div className={`form-group ${field.name}`}>
              <Field
                id={field.name}
                key={field.name}
                {...field}
                registerInput={register}
                errors={errors}
                message={field.message}
              />
            </div>
          </div>
        )
      )}
      <div className="row address-book-card-buttons">
        <Button
          type="reset"
          className="address-book-card__cancel button button-tertiary button-size-sm"
          disabled={isLoadingEditAddress || isLoadingNewAddress}
          onClick={handleCancel}
          variant="nonary"
          form="br-50"
        >
          {labels?.cancel}
        </Button>
        <Button
          className="address-book-card__submit"
          type="submit"
          variant="nonary"
          form="br-50"
          disabled={isLoadingEditAddress || isLoadingNewAddress}
          onClick={handleSubmitClick}
        >
          {labels?.save}
        </Button>
      </div>
    </form>
  );
};

export default AddressForm;
