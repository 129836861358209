import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { LocalizedContext } from "../../../services/LocalizedContextService";
import { deleteUserAddress, getUserProfile } from "../../../services/salesforce/Account/user";
import { getAuthUser } from "../../../services/salesforce/Auth";
import Button from "../../Button";
import DeleteConfirmation from "../DeleteConfirmation";
import AddressCard from "./AddressCard";
import AddressForm from "./AddressForm";
import LoaderIcon from "../../../images/icons/loader";

import "./styles.scss";

export interface AddressInterface {
  addressId: string;
  address1: string;
  address2: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  city: string;
  postalCode: string;
  stateCode: string;
  phone: string;
}

const initialAddressState = {
  addressId: "",
  address1: "",
  address2: "",
  firstName: "",
  lastName: "",
  countryCode: "",
  city: "",
  postalCode: "",
  stateCode: "",
  phone: ""
};

const AddressBook = () => {
  const user = getAuthUser();

  const { ctaLabels } = useContext(LocalizedContext).sanityLabels || {};

  const [deleteStatus, setDeleteStatus] = useState<{ status: boolean; data?: string }>({
    status: false,
    data: ""
  });

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [addressesData, setAddressesData] = useState([]);

  const getProfileData = () => {
    const profileDataPromise = getUserProfile(user.rcid || "");
    profileDataPromise.then(data => {
      data && setAddressesData(data.addresses);
      setIsLoadingData(false);
    });
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const { mutate: deleteAddress } = useMutation({
    mutationFn: () => deleteUserAddress(user.rcid, deleteStatus.data),
    onSuccess: () => {
      setDeleteStatus({ status: false });
    }
  });

  const [addressFormState, setAddressFormState] = useState<{ status: boolean; data: AddressInterface; state: string }>(
    () => ({
      status: false,
      state: "NEW",
      data: initialAddressState
    })
  );

  const handleDelete = () => {
    deleteAddress();
  };

  useEffect(() => {
    getProfileData();
  }, [addressFormState.status, deleteStatus.status]);

  return (
    <div className="address-book">
      {isLoadingData ? (
        <LoaderIcon width={100} height={100} className="loaderIcon" />
      ) : (
        <div className="address-book__wrapper">
          {addressesData && (
            <div className="address-book-list">
              {addressesData.map((address: any) => {
                return addressFormState.status &&
                  addressFormState.state == "EDIT" &&
                  address.addressId == addressFormState.data?.addressId ? (
                  <AddressForm
                    data={addressFormState.data}
                    handleCancel={() => setAddressFormState({ status: false, data: initialAddressState, state: "NEW" })}
                    formState={addressFormState.state}
                  />
                ) : (
                  <AddressCard
                    key={address.addressId}
                    address={address}
                    handleEdit={(data: any) => setAddressFormState({ status: true, data, state: "EDIT" })}
                    handleDelete={(id: string) => {
                      setDeleteStatus({ status: true, data: id });
                    }}
                  />
                );
              })}
            </div>
          )}
          {addressFormState.status ? (
            addressFormState.state == "NEW" ? (
              <AddressForm
                data={addressFormState.data}
                handleCancel={() => setAddressFormState({ status: false, data: initialAddressState, state: "NEW" })}
                formState={addressFormState.state}
              />
            ) : null
          ) : (
            <div className="row address-book-buttons">
              <Button
                className="address-card__add"
                variant="nonary"
                form="br-50"
                onClick={() => setAddressFormState({ status: true, data: initialAddressState, state: "NEW" })}
              >
                {ctaLabels?.add}
              </Button>
            </div>
          )}

          {deleteStatus.status && (
            <DeleteConfirmation
              showModal={deleteStatus.status}
              confirmModal={handleDelete}
              hideModal={() => setDeleteStatus({ status: false })}
              message={`Are you sure you want to delete item ${deleteStatus.data}?`}
              ctaCancel={ctaLabels?.cancel || ""}
              ctaDelete={ctaLabels?.delete || ""}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AddressBook;
