import React, { useContext } from "react";
import { LocalizedContext } from "../../../services/LocalizedContextService";
import Button from "../../Button";

const AddressCard = ({ address, handleEdit, handleDelete }: { address: any; handleEdit: any; handleDelete: any }) => {
  const { ctaLabels: labels } = useContext(LocalizedContext).sanityLabels || {};

  const { addressId, firstName, lastName, address1, address2, city, stateCode, postalCode } = address;

  return (
    <div className="address-book-card__wrapper">
      <div className="address-book-card__user-info">
        <div className="address-book-card__title text__size-h4">{addressId}</div>
        <div>
          <span>{firstName}</span> <span>{lastName}</span>
        </div>
        <div>{`${address1}${address2 ? " " + address2 : ""} `}</div>
        <div>
          <span>{city},</span> <span>{stateCode}</span> <span>{postalCode}</span>
        </div>
      </div>
      <div className="row address-book-card-buttons">
        <Button
          variant="nonary"
          form="br-50"
          className="address-book-card__delete"
          onClick={() => handleDelete(addressId)}
        >
          {labels?.delete}
        </Button>
        <Button variant="nonary" form="br-50" className="address-book-card__edit" onClick={() => handleEdit(address)}>
          {labels?.edit}
        </Button>
      </div>
    </div>
  );
};

export default AddressCard;
