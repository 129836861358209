import React, { FunctionComponent } from "react";
import Button from "../../Button";

import "./styles.scss";

export interface DeleteConfirmationInterface {
  showModal: boolean;
  hideModal: any;
  confirmModal: any;
  message: string;
  ctaDelete: string;
  ctaCancel: string;
}

const DeleteConfirmation: FunctionComponent<DeleteConfirmationInterface> = ({
  showModal,
  hideModal,
  confirmModal,
  message,
  ctaDelete,
  ctaCancel
}) => {
  return (
    <div className="delete-confirmation">
      <div className="delete-confirmation__dark-bg" onClick={hideModal} />
      <div className="delete-confirmation__modal">
        <div className="delete-confirmation__modal-content">
          <p className="delete-confirmation__heading text__size-h5">{message}</p>

          <div className="delete-confirmation__buttons">
            <Button variant="nonary" form="br-50" className="delete" onClick={confirmModal}>
              {ctaDelete}
            </Button>
            <Button variant="nonary" form="br-50" className="cancel" onClick={hideModal}>
              {ctaCancel}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmation;
